@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';

body {
    font-family: 'Poppins', sans-serif;
}

img {
    user-select: none;
}